import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { editPostApi } from "./post.service";
import { BUCKET_URL } from "../../constants/api.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import "bootstrap/dist/css/bootstrap.min.css";
import { getAllDataApi, imageUploadAPI } from "../category/category.service";
import PostForm from "./post-form";

const PostEditComponent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const [categories, setCategories] = useState([
    { name: "Set the categories", value: "" },
  ]);

  const [all, setAll] = useState({
    id: "",
    title: "",
    price: "",
    status: 1,
    images: [],
    location: "",
    is_featured: "",

    category_id: "",
    is_featured: "",
    description: "",
    contact_name: "",
    contact_number: "",
    customer_id: "",
  });

  const handleChange = async (name, value) => {
    console.log(name, value);

    if (typeof name === "string" && name.startsWith("image")) {
      const index = parseInt(name.replace("image", ""), 10);

      try {
        const resp = await imageUploadAPI(value);
        const imageUrl = BUCKET_URL + "/app-source/images/" + resp?.data;

        const newImages = Array.isArray(all.images) ? [...all.images] : [];

        if (index >= 0 && index < newImages.length) {
          newImages[index] = { image: imageUrl };
        } else {
          newImages.push({ image: imageUrl });
        }

        setAll({ ...all, images: newImages });
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    } else if (typeof name === "object" && name !== null) {
      setAll({ ...all, customer_id: name.value });
    } else {
      setAll({ ...all, [name]: value });
    }
  };

  // console.log(all.images);

  const handleSubmit = async () => {
    console.log(all);

    const resp = await editPostApi(all?.id, all);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/post");
    }
  };

  const onload = async () => {
    const response = await getAllDataApi();
    // console.log(response?.data);

    let formatData = [];
    formatData = response?.data.map((data) => {
      return {
        name: data.name,
        value: data.id,
      };
    });
    setCategories((prevCategories) => [...prevCategories, ...formatData]);
  };
  useEffect(() => {
    onload();
  }, []);
  useEffect(() => {
    setAll(state);
  }, [state]);
  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit post" backRoute="/post" />
        <PostForm
          all={all}
          setAll={setAll}
          categories={categories}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </PageWrapperComponent>
  );
};
export default PostEditComponent;
