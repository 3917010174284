import React from "react";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import ButtonComponent from "../../components/button/button.component";
import FormInputSelectComponent from "../../components/form-inputs/form-input-select/form-input-select.component";
import FormInputFileUploadComponent from "../../components/form-inputs/form-file-upload/form-file-upload.component";

const CategoryForm = ({ all, handleChange, handleSubmit }) => {
  return (
    <div className="account-content">
      <div className="container">
        <FormInputTextComponent
          name={"name"}
          label={"name"}
          onChange={handleChange}
          placeholder={"Enter the category name"}
          value={all?.name}
        />
      </div>

      <div
        className="container mt-10"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <span
          style={{
            width: "82%",
          }}
        >
          <FormInputFileUploadComponent
            name={"image"}
            label={"image"}
            placeholder={"Upload the Category image"}
            onChange={handleChange}
          />
        </span>
        <span
          style={{
            // width: "2%",
            display: "flex",
            justifyContent: "flex-end",
          }}
          className=" mt-8"
        >
          {all?.image && (
            <img
              src={all?.image}
              alt="Post Preview"
              style={{
                width: "50px",
                borderRadius: "10px",
                height: "35px",
              }}
            />
          )}
        </span>
      </div>

      <div className="container mt-10">
        <FormInputSelectComponent
          id={"status"}
          label={"status"}
          name={"status"}
          placeholder={"Select status"}
          onChange={handleChange}
          options={[
            { name: "Enabled", value: 1 },
            { name: "Disabled", value: 2 },
          ]}
        />
      </div>

      <div className="container mt-10">
        <ButtonComponent text="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default CategoryForm;
