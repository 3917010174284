import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import {
  FaArrowRight,
  FaBox,
  FaDoorOpen,
  FaLink,
  FaToolbox,
  FaUsers,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountHeaderComponent from "../../components/account-header/account-header.component";
import Swal from "sweetalert2";
import { resetUserTokenAction } from "../../reducers/app.reducer";
import { getAdminInfoAPI } from "./account.service";

const AccountComponent = () => {
  const { user } = useSelector((state) => state.app);
  const [all, setAll] = useState({ total_category: 0, total_post: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoScreen = (url = "/") => {
    navigate(url);
  };

  const handleLogoutConfirm = () => {
    dispatch(resetUserTokenAction());
    gotoScreen("/");
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Logout !",
      text: "Are you sure to logout?",
      icon: "info",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleLogoutConfirm();
      } else if (result.isDismissed) {
        console.log("Dismissed ");
      }
    });
  };

  const onLoad = async () => {
    const resp = await getAdminInfoAPI();
    console.log({ resp });
    if (resp.status)
      setAll({
        total_category: resp.data.category_count,
        total_post: resp.data.post_count,
      });
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="My Account" backRoute="/" />

        <div className="account-content">
          <div className="container card t-a-c" style={{ marginTop: 10 }}>
            <p className="account-name no-padding no-margin"> {user?.name} </p>
            <p className="account-mobile no-padding no-margin">
              {" "}
              {user?.mobile}{" "}
            </p>
            <p className="account-role no-padding no-margin">
              {user?.role_name}
            </p>
          </div>

          <div className="container card mt-10 flex">
            <div className="profile-card">
              <img
                className="grid-item-image"
                src={require("./../../assets/icons/box.png")}
              />
              <p> Total Category </p>
              <p className="p-color"> {all.total_category} </p>
            </div>

            <div className="profile-card">
              <img
                className="grid-item-image"
                src={require("./../../assets/icons/blog.png")}
              />
              <p> Total Posts </p>
              <p className="p-color"> {all.total_post} </p>
            </div>
          </div>

          <div className="container mt-10">
            <div className="flex" onClick={() => gotoScreen("/category")}>
              <div className="list-image">
                <FaBox />
              </div>
              <div className="list-name">
                <p> All Category</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>

            <div className="flex" onClick={() => gotoScreen("/post")}>
              <div className="list-image">
                <FaToolbox />
              </div>
              <div className="list-name">
                <p> All Post</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={() => gotoScreen("/quick-link")}>
              <div className="list-image">
                <FaLink />
              </div>
              <div className="list-name">
                <p> All Quick Links</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>

            <div className="flex" onClick={() => gotoScreen("/customers")}>
              <div className="list-image">
                <FaUsers />
              </div>
              <div className="list-name">
                <p> All Customers</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
            <div className="flex" onClick={handleLogout}>
              <div className="list-image">
                <FaDoorOpen />
              </div>
              <div className="list-name">
                <p> Logout</p>
              </div>
              <div className="list-arrow">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AccountComponent;
