import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { editCustomerApi } from "./customers.service";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerForm from "./customers-form";

const EditCustomerComponent = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const [all, setAll] = useState({
    id: "",
    name: "",
    phone: "",
    address: "",
    location: "",
  });

  const handleChange = async (name, value) => {
    console.log({ name, value });
    setAll({ ...all, [name]: value });
  };

  const handleSubmit = async () => {
    // console.log(all);

    const resp = await editCustomerApi(all?.id, all);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/customers");
    }
  };
  useEffect(() => {
    setAll(state);
  }, [state]);

  // console.log({ all });

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Customer" backRoute="/customers" />
        <div style={{ paddingTop: 10 }}>
          <CustomerForm
            all={all}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default EditCustomerComponent;
