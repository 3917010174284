import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { useNavigate } from "react-router-dom";
import { createCustomerAPI } from "./customers.service";
import CustomerForm from "./customers-form";

const AddCustomerComponent = ({
  isShowheader = true,
  nameValue = "",
  setClose,
}) => {
  const [all, setAll] = useState({
    name: "",
    phone: "",
    address: "",
    location: "",
  });
  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const handleChange = async (name, value) => {
    // console.log({ name, value });
    setAll({ ...all, [name]: value });
  };

  const handleSubmit = async () => {
    console.log(all);

    const resp = await createCustomerAPI(all);
    if (resp.status) {
      if (!isShowheader) {
        setClose(false);
      } else {
        gotoScreen("/customers");
      }
    }
  };

  useEffect(() => {
    if (!isShowheader) {
      setAll((prevState) => ({
        ...prevState,
        name: nameValue,
      }));
    }
  }, [isShowheader, nameValue]);

  return (
    <PageWrapperComponent>
      <div className="page-container">
        {isShowheader && (
          <AccountHeaderComponent name="Add Customers" backRoute="/customers" />
        )}

        <div>
          <CustomerForm
            all={all}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default AddCustomerComponent;
