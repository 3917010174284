import React from "react";
import { FaPhone, FaShare, FaWhatsapp } from "react-icons/fa";
import { APP_URL } from "../../constants/api.constants";

const PostShareComponent = ({ post }) => {
  const handleShare = (post) => {
    if (navigator.share) {
      // Web Share API (works on mobile and some desktop browsers)
      navigator
        .share({
          title: "Check out this post!",
          url: APP_URL + "view-post/" + post.unique_id, // The URL to share
        })
        .catch((error) => {
          console.log("Error sharing:", error);
        });
    }
  };

  return (
    <div className="contact-seller-mode">
      <span
        className="contact-button contact-mode"
        style={{
          //   marginLeft: "30px",
          backgroundColor: "#65ce40 ",
        }}
      >
        <a
          href={`https://wa.me/+91${post.customer_details?.phone.replace(
            /\D/g,
            ""
          )}?text=${encodeURIComponent(
            "Hi, I'm interested in this post "
          )} https://vagbay.com/view-post/${post.unique_id}`}
        >
          <FaWhatsapp style={{ width: 20, height: 20 }} color="white" />
        </a>
      </span>

      <span
        className="contact-button contact-mode"
        style={{ backgroundColor: "rgb(78 190 255)" }}
      >
        <a href={`tel:${post?.customer_details?.phone}`}>
          <FaPhone style={{ width: 20, height: 20 }} color="white" />
        </a>
      </span>

      <span
        className="contact-button contact-mode"
        style={{ backgroundColor: "rgb(221 179 23)" }}
      >
        <a onClick={() => handleShare(post)}>
          <FaShare style={{ width: 20, height: 20 }} color="white" />
        </a>
      </span>
    </div>
  );
};

export default PostShareComponent;
