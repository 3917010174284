import React, { useEffect, useState } from "react";
import FormInputTextComponent from "../../components/form-inputs/form-input-text/form-input-text.component";
import ButtonComponent from "../../components/button/button.component";
import FormInputSelectComponent from "../../components/form-inputs/form-input-select/form-input-select.component";
import FormInputFileUploadComponent from "../../components/form-inputs/form-file-upload/form-file-upload.component";
import FormInputTextAreaComponent from "../../components/form-inputs/form-input-text/form-input-textarea.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { customerAutoCompleteApi } from "../customers/customers.service";
import FormInputTextAutoCompeleteComponent from "../../components/form-inputs/form-input-text-autocomplete/form-input-auto-complete.componet";
import { Sheet } from "react-modal-sheet";
import AddCustomerComponent from "../customers/add-customers.component";
import FeatherIcon from "feather-icons-react";

const PostForm = ({ all, setAll, categories, handleChange, handleSubmit }) => {
  const [fields, setFields] = useState([]);
  const [openSheet, setOpenSheet] = useState(false);
  const [customerName, setCustomerName] = useState("");

  const handleFileChange = async (name, value) => {
    console.log("File selected:", name);
    const newFields = [...fields];

    newFields[name] = value;
    setFields(newFields);
    handleChange(name, value);
  };

  const addField = () => {
    setFields([...fields, { image: "" }]);
  };

  const removeField = (index) => {
    const updatedFields = fields.filter((_, idx) => idx !== index);
    setFields(updatedFields);

    setAll((prevState) => ({
      ...prevState,
      images: updatedFields,
    }));
  };

  useEffect(() => {
    setFields(all.images?.length > 0 ? all.images : [{ image: "" }]);
  }, [all]);

  const handleInputIconClick = (value) => {
    console.log("Icon clicked", value);
    setCustomerName(value);
    setOpenSheet(true);
  };

  return (
    <>
      <div className="account-content">
        <div className="container mt-10">
          <FormInputTextComponent
            label={"Title"}
            name={"title"}
            onChange={handleChange}
            placeholder={"Enter the Title"}
            value={all?.title}
          />
        </div>

        <div className="container mt-10">
          <FormInputTextAutoCompeleteComponent
            label={"Customer"}
            name={"customer_id"}
            onChange={handleChange}
            placeholder={"Select  the Customer"}
            value={all?.customer_id || ""}
            fetchOptions={customerAutoCompleteApi}
            inputIcon={"plus"}
            handleInputIconClick={handleInputIconClick}
            formatOption={(option) => `${option.name} - ${option.phone}`}
          />
        </div>
        <div className="container mt-10">
          <FormInputTextComponent
            label={"Price"}
            name={"price"}
            onChange={handleChange}
            placeholder={"Enter the Price"}
            value={all?.price}
          />
        </div>
        {/* <div className="container mt-10">
          <FormInputTextComponent
            label={"Location"}
            name={"location"}
            onChange={handleChange}
            placeholder={"Enter the Location "}
            value={all?.location}
          />
        </div> */}
        <div className="container mt-10">
          <FormInputTextAreaComponent
            label={"Description"}
            name={"description"}
            onChange={handleChange}
            placeholder={"Enter the Decription "}
            value={all?.description}
          />
        </div>
        {/* <div className="container mt-10">
          <FormInputTextComponent
            label={"Contact Name"}
            name={"contact_name"}
            onChange={handleChange}
            placeholder={"Enter the Contact  name"}
            value={all?.contact_name}
          />
        </div>
        <div className="container mt-10">
          <FormInputTextComponent
            label={"Contact Number"}
            name={"contact_number"}
            onChange={handleChange}
            placeholder={"Enter the Contact number"}
            value={all?.contact_number}
          />
        </div> */}
        <div className="container mt-10">
          <FormInputSelectComponent
            label={"Is Featured Post?"}
            id={"is_featured"}
            name={"is_featured"}
            placeholder={"Is Featured Post?"}
            options={[
              { name: "- Select - ", value: "" },

              { name: "YES", value: 1 },
              { name: "NO", value: 2 },
            ]}
            onChange={handleChange}
            value={all?.is_featured}
          />
        </div>
        <div className="container mt-10">
          <FormInputSelectComponent
            label={"Category"}
            id={"category_id"}
            name={"category_id"}
            placeholder={"Set the category"}
            options={categories}
            onChange={handleChange}
            value={all?.category_id}
          />
        </div>
        <div className="container mt-10">
          <FormInputSelectComponent
            label={"Status"}
            id={"status"}
            name={"status"}
            placeholder={"Select status"}
            onChange={handleChange}
            options={[
              { name: "Select the status", value: "" },

              { name: "Enabled", value: 1 },
              { name: "Disabled", value: 2 },
            ]}
            value={all?.status}
          />
        </div>
        <div className="container mt-10">
          {fields.map((field, index) => (
            <div
              key={index}
              className="field-container"
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              <span style={{ width: "82%" }}>
                <FormInputFileUploadComponent
                  label={`images ${index + 1}`}
                  name={`image`}
                  placeholder={"Upload the Category image"}
                  onChange={handleFileChange}
                />
              </span>
              <span
                className="mt-10"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginLeft: "30px",
                }}
              >
                {field?.image && (
                  <img
                    src={field.image}
                    alt="Post Preview"
                    style={{
                      width: "50px",
                      borderRadius: "10px",
                      height: "35px",
                    }}
                  />
                )}
              </span>

              <span
                className={`mt-10 add-button ${
                  index === fields.length - 1 ? "add" : "delete"
                }`}
                role="button"
                aria-label={
                  index === fields.length - 1 ? "Add field" : "Remove field"
                }
              >
                <FontAwesomeIcon
                  icon={index === fields.length - 1 ? faPlus : faTrash}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (index === fields.length - 1) {
                      addField();
                    } else {
                      removeField(index);
                    }
                  }}
                />
              </span>
            </div>
          ))}
        </div>
        <div className="container mt-10">
          <ButtonComponent text="Submit" onClick={handleSubmit} />
        </div>
      </div>

      <Sheet
        isOpen={openSheet}
        onClose={() => setOpenSheet(false)}
        snapPoints={[0.8]}
      >
        <Sheet.Container>
          <Sheet.Header>
            <p className="no-padding no-margin mt-30 t-a-c ">
              Create Customer
              <span style={{ float: "right" }}>
                <button
                  className="cancel-button"
                  onClick={() => setOpenSheet(false)}
                >
                  <FeatherIcon icon={"x"} width={30} />
                </button>
              </span>
            </p>
          </Sheet.Header>

          <Sheet.Content>
            <AddCustomerComponent
              isShowheader={false}
              nameValue={customerName}
              setClose={setOpenSheet}
            />
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop onClick={() => setOpenSheet(false)} />
      </Sheet>
    </>
  );
};

export default PostForm;
