import React from "react";

const ButtonComponent = ({ text, onClick }) => {
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div onClick={handleClick} className="primary-button">
      <p> {text} </p>
    </div>
  );
};

export default ButtonComponent;
