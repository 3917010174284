import "./App.css";

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import HomeComponent from "./screens/home/index.component";
import LoginComponent from "./screens/login/login.component";
import AccountComponent from "./screens/account/account.component";
import CategoryComponent from "./screens/category/category.component";
import AddCategoryComponent from "./screens/category/add-category.component";
import PostCreateComponent from "./screens/post/post-create.component";
import PostComponent from "./screens/post/post.component";
import EditCategoryComponent from "./screens/category/edit-category.component";
import PostEditComponent from "./screens/post/post-edit.component";
import QuickLinkComponent from "./screens/quick-links/quick-links.component";
import AddQuickLinkComponent from "./screens/quick-links/add-quick-links.component";
import EditQuickLinkComponent from "./screens/quick-links/edit-quick-links.component";
import ViewCategoryProductsComponent from "./components/app-categories/view-category-product.component";
import CustomerComponent from "./screens/customers/customers.component";
import AddCustomerComponent from "./screens/customers/add-customers.component";
import EditCustomerComponent from "./screens/customers/edit-customers.component";
import CustomerProductsComponent from "./components/customer-products/customer-product.component";
import ViewPostComponent from "./screens/view-post/view-post";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComponent />}></Route>
        <Route path="/login" element={<LoginComponent />}></Route>
        <Route path="/account" element={<AccountComponent />}></Route>
        <Route path="/category" element={<CategoryComponent />}></Route>
        <Route path="/category/add" element={<AddCategoryComponent />}></Route>
        <Route
          path="/category/edit"
          element={<EditCategoryComponent />}
        ></Route>

        <Route path="/post" element={<PostComponent />}></Route>

        <Route path="/post/add" element={<PostCreateComponent />}></Route>
        <Route path="/post/edit/" element={<PostEditComponent />}></Route>

        <Route path="/quick-link" element={<QuickLinkComponent />}></Route>

        <Route
          path="/quick-link/add"
          element={<AddQuickLinkComponent />}
        ></Route>
        <Route
          path="/quick-link/edit/"
          element={<EditQuickLinkComponent />}
        ></Route>

        <Route
          path="/view-post/:uniqueId"
          element={<ViewPostComponent />}
        ></Route>
        <Route
          path="/view-customer-post/:uniqueId"
          element={<CustomerProductsComponent />}
        ></Route>
        <Route
          path="/view-category"
          element={<ViewCategoryProductsComponent />}
        ></Route>

        <Route path="/customers" element={<CustomerComponent />}></Route>

        <Route path="/customers/add" element={<AddCustomerComponent />}></Route>
        <Route
          path="/customers/edit/"
          element={<EditCustomerComponent />}
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
