import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const AccountHeaderComponent = ({ name, backRoute = "/" }) => {
  const navigate = useNavigate();

  const gotoScreen = () => {
    navigate(backRoute);
  };
  return (
    <div className="flex account-header">
      <div className="back-button" onClick={gotoScreen}>
        <FaArrowLeft />
      </div>
      <div className="screen-title">
        <p className="no-padding no-margin "> {name} </p>
      </div>
    </div>
  );
};

export default AccountHeaderComponent;
