import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";

import FormInputTextComponent from "../form-input-text/form-input-text.component";

const FormInputTextAutoCompeleteComponent = ({
  id,
  name,
  placeholder,
  value,
  // options = [],
  onChange,
  threshold = 300,
  inputIcon,
  label,
  fetchOptions,
  formatOption,
  handleInputIconClick,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    value ? { ...value } : {}
  );
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const debouncedFetchResults = useCallback(
    _.debounce(async (searchQuery) => {
      if (fetchOptions) {
        const res = await fetchOptions(searchQuery);
        // console.log({ res, searchQuery });
        if (searchQuery && res.status) {
          setShowOptions(true);
          const formattedOptions = res.data.map((option) => {
            return {
              name: formatOption ? formatOption(option) : option.name,
              value: option.id,
              data: option,
            };
          });

          setOptions(formattedOptions);
        } else {
          setShowOptions(false);
          setOptions([]);
        }
      }
    }, 300),
    []
  );

  const handleChange = async (name, value) => {
    const updatedValue = {
      ...selectedOption,
      name: value,
      value: null,
    };
    setSelectedOption(value ? updatedValue : {});

    // console.log(value);

    if (!value || value == "") setShowOptions(false);
    else setShowOptions(false);

    debouncedFetchResults(value);
  };

  const handleOptionClick = (option) => {
    const updatedValue = {
      ...selectedOption,
      name: option.name,
      value: option.value,
      data: option?.data || null,
    };

    setShowOptions(false);
    setSelectedOption(updatedValue);
    onChange(updatedValue);
    // console.log(updatedValue);
  };

  useEffect(() => {
    const suggestionStatus =
      options.length > 0 && selectedOption.name && !selectedOption.value
        ? true
        : false;
    // console.log({ suggestionStatus, options, selectedOption });
    setShowOptions(suggestionStatus);
  }, [options, selectedOption]);

  useEffect(() => {
    if (value) {
      setSelectedOption({ ...value });
    }
  }, [value]);
  // console.log(selectedOption.name);
  // console.log(selectedOption);

  return (
    <>
      <div className="col-12">
        <div>
          {/* {label && <p className="no-margin form-input-label"> {label} </p>} */}

          <div className="autocomplete" style={{ width: "100%" }}>
            <FormInputTextComponent
              label={label}
              id={id}
              name={name}
              value={selectedOption.name}
              placeholder={placeholder}
              onChange={handleChange}
              handleInputIconClick={handleInputIconClick}
              inputIcon={inputIcon}
            />

            <div
              className={`autocomplete-items ${
                showOptions > 0 ? "" : "hidden"
              }`}
            >
              {options.map((option) => (
                <div
                  id={`${id}-option-${option.value}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormInputTextAutoCompeleteComponent;
