export const BASE_URL = "https://vag-parts-api.triamiz.com/api";
export const BUCKET_URL = "https://vag-parts.s3.ap-south-1.amazonaws.com/v1";
// export const BASE_URL = "http://localhost:8000/api";

export const APP_NAME = "VAG BAY";

export const APP_URL = "https://vagbay.com/";

export const API = {
  LOGIN: `${BASE_URL}/login`,
  HOME: `${BASE_URL}/home`,
  ADMIN_INFO: `${BASE_URL}/admin-info`,
  CREATE_CATEGORY: `${BASE_URL}/category/create`,
  EDIT_CATEGORY: `${BASE_URL}/category/edit/`,
  DELETE_CATEGORY: `${BASE_URL}/category/delete`,
  GET_POST_BY_ID: `${BASE_URL}/post/`,
  GET_RELATED_POST_BY_ID: `${BASE_URL}/related-posts/`,

  UPLOAD_IMAGE: `${BASE_URL}/upload/image-file`,
  CATEGORY_ALL: `${BASE_URL}/category/all`,
  CATEGORY_LIST_ALL: `${BASE_URL}/category/getAll`,

  CREATE_POST: `${BASE_URL}/post/create`,
  POST_ALL: `${BASE_URL}/post/all`,
  EDIT_POST: `${BASE_URL}/post/edit/`,
  DELETE_POST: `${BASE_URL}/post/delete`,
  CUSTOMER_POST_LIST: `${BASE_URL}/post/getByCustomer/`,

  CREATE_QUICK_LINK: `${BASE_URL}/quick-link/create`,
  QUICK_LINK_LIST: `${BASE_URL}/quick-links`,
  EDIT_QUICK_LINK: `${BASE_URL}/quick-link/edit/`,
  DELETE_QUICK_LINK: `${BASE_URL}/quick-link/delete`,
  QUICK_LINK_ALL: `${BASE_URL}/quick-links/all`,

  CREATE_CUSTOMER: `${BASE_URL}/customers/create`,
  CUSTOMER_LIST: `${BASE_URL}/customers`,
  EDIT_CUSTOMER: `${BASE_URL}/customers/edit/`,
  DELETE_CUSTOMER: `${BASE_URL}/customers/delete`,
  CUSTOMERS_LIST_ALL: `${BASE_URL}/customers/list`,
  CUSTOMERS_AUTO_COMPLETE: `${BASE_URL}/customers/autoComplete`,
};
