import React, { useState } from "react";
import LoaderComponent from "../../loader/loader";

const FormInputFileUploadComponent = ({
  name,
  id,
  value,
  placeholder,
  classnames,
  icon,
  error,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setLoading(true); // Start loading
    console.log({ files: e.target.files });

    if (onChange) {
      onChange(name, e.target.files[0]);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <input
          className={classnames || "form-input-file-upload"}
          type={"file"}
          name={name}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
      <div>
        {loading && (
          <div style={{ marginTop: 5, position: "relative", marginLeft: 11 }}>
            <div style={{ width: 25, float: "left" }}>
              <img
                className="loader-image"
                src={require("../../../assets/icons/loading-gif.gif")}
              />
            </div>
          </div>
        )}
      </div>
      {error && <span className="form-input-error"> {error} </span>}

      {icon && <span className="form-input-icon"> {icon} </span>}
    </div>
  );
};

export default FormInputFileUploadComponent;
