import moment from "moment";

export const formatDateUtil = (date) => {
  return moment(date).format("DD-MMM-YYYY HH:mm:ss").toUpperCase();
};

export const formatDate = (date) => {
  if (date)
    return moment(date, "DD-MM-YYYY HH:mm:ss")
      .format("DD-MMM-YYYY")
      .toUpperCase();
  return "N/A";
};
