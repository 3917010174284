import React, { useEffect, useState } from "react";

import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AccountHeaderComponent from "../../components/account-header/account-header.component";

import { editCategoryApi, imageUploadAPI } from "./category.service";
import { BUCKET_URL } from "../../constants/api.constants";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryForm from "./category-form";

const EditCategoryComponent = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const gotoScreen = (url) => {
    navigate(url);
  };
  const [all, setAll] = useState({
    id: "",
    name: "",
    nameError: "",
    status: "",
    image: "",
  });

  const handleChange = async (name, value) => {
    console.log({ name, value });
    if (name === "image") {
      const resp = await imageUploadAPI(value);

      let image = BUCKET_URL + "/app-source/images/";

      // console.log({ resp });
      setAll({ ...all, [name]: image + resp?.data });
    } else setAll({ ...all, [name]: value });
  };

  const handleSubmit = async () => {
    // console.log(all);

    const resp = await editCategoryApi(all?.id, all);
    console.log(resp.status);
    if (resp.status) {
      gotoScreen("/category");
    }
  };
  useEffect(() => {
    setAll(state);
  }, [state]);

  console.log({ all });

  return (
    <PageWrapperComponent>
      <div className="page-container">
        <AccountHeaderComponent name="Edit Category" backRoute="/category" />
        <div style={{ paddingTop: 10 }}>
          <CategoryForm
            all={all}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default EditCategoryComponent;
