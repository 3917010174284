import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AccountHeaderComponent from "../account-header/account-header.component";

import LoaderComponent from "../loader/loader";
import { getPostByCustomerApi } from "../../screens/post/post.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faAddressCard,
  faHandPointDown,
  faHome,
  faLocation,
  faLocationArrow,
  faLocationDot,
  faLocationPin,
  faLocationPinLock,
  faMessage,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FaLocationArrow, FaShare } from "react-icons/fa";
import PriceComponent from "../price/price";
import PostShareComponent from "../post-share/post-share";
import { APP_URL } from "../../constants/api.constants";
import PageWrapperComponent from "../page-wrapper/page-wrapper.component";

const CustomerProductsComponent = () => {
  const { state } = useLocation();
  const { uniqueId } = useParams();
  const [all, setAll] = useState({ customer: {} });
  const [allPost, setAllPostData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(uniqueId);

  const navigate = useNavigate();
  const onLoad = async () => {
    const resp = await getPostByCustomerApi(uniqueId);

    if (resp.status) {
      setLoading(false);
      setAllPostData(resp?.data.posts);
      setAll({ ...all, customer: resp.data.customer });
      // console.log(resp?.data);
    }
  };
  useEffect(() => {
    onLoad();
  }, [state]);

  const handleClick = (post) => {
    setLoading(true);
    navigate(`/view-post/${post.unique_id}`);
  };

  const handleCustomerLinkShare = () => {
    const url = APP_URL + "view-customer-post/" + uniqueId;
    if (navigator.share) {
      // Web Share API (works on mobile and some desktop browsers)
      navigator
        .share({
          title: "Check out this post!",
          url: url, // The URL to share
        })
        .catch((error) => {
          console.log("Error sharing:", error);
        });
    }
  };

  return (
    <PageWrapperComponent>
      <div className="view-product">
        <AccountHeaderComponent name="Home" backRoute="/" />
        {loading ? (
          <div className="t-a-c" style={{ paddingTop: 125 }}>
            <LoaderComponent
              path={require("./../../assets/icons/loading-gif.gif")}
            />
          </div>
        ) : (
          <div style={{ paddingTop: 30, padding: 20 }}>
            <div className="customer-details-container">
              <span className="customer-info">
                <img
                  className="profile-image t-a-c"
                  src={require("./../../assets/icons/profile.png")}
                  alt="Profile"
                />
                <div className="customer-info-details">
                  <div className="customer-info-item">
                    <span className="info-label">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span className="info-value">
                      {all.customer?.name || "name"}
                    </span>
                  </div>
                  <div className="customer-info-item">
                    <span className="info-label">
                      {" "}
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <span className="info-value">
                      {all.customer?.phone || "phone"}
                    </span>
                  </div>
                  <div className="customer-info-item">
                    <span className="info-label">
                      <FontAwesomeIcon icon={faHome} />
                    </span>
                    <span className="info-value pr-10">
                      {all.customer?.address || "address"}
                    </span>
                  </div>
                  <div className="customer-info-item">
                    <span className="info-label">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                    <span className="info-value" style={{ marginLeft: "25px" }}>
                      {all.customer?.location || "location"}
                    </span>
                  </div>
                </div>

                <div style={{ marginTop: 10 }} className="t-a-c">
                  <span
                    className="contact-button contact-mode"
                    style={{
                      backgroundColor: "rgb(221 179 23)",
                      padding: "10px 9px 11px 12px",
                    }}
                  >
                    <a onClick={() => handleCustomerLinkShare()}>
                      <span style={{ marginRight: 10 }}>Share Profile</span>
                      <FaShare
                        style={{ width: 10, height: 10 }}
                        color="white"
                      />
                    </a>
                  </span>
                </div>
              </span>
            </div>

            {allPost.length > 0 ? (
              <>
                <div>
                  <h3 className="t-a-c">{state?.name || "Customer"}'s Posts</h3>
                </div>

                <div className="grid-container">
                  {allPost.map((post, i) => (
                    <div
                      className="grid-product-item left"
                      onClick={() => handleClick(post)}
                    >
                      <img
                        className="grid-product-item-image"
                        src={
                          post?.images && post.images.length > 0
                            ? post.images[0].image
                            : require("./../../assets/icons/no-photos.png")
                        }
                        alt={post?.title || "Product Image"}
                      />
                      <div className="grid-content">
                        <p className="grid-item-text">{post.title}</p>
                        <p className="grid-item-location">
                          {post?.customer_details?.name},{" "}
                          {post?.customer_details?.phone}
                        </p>
                      </div>

                      <div className="grid-item-price">
                        <PriceComponent price={post.price} />
                      </div>

                      <PostShareComponent post={post} />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p>No Post data available.</p>
            )}
          </div>
        )}
      </div>
    </PageWrapperComponent>
  );
};

export default CustomerProductsComponent;
