import React from "react";
import PageWrapperComponent from "../../components/page-wrapper/page-wrapper.component";
import AppHeaderComponent from "../../components/app-header/app-header.component";
import AppCategoriesComponent from "../../components/app-categories/app-categories.component";
import AppProductsComponent from "../../components/app-products/app-products.component";

import { FaPlus } from "react-icons/fa";
import FloatingButtonComponent from "../../components/floating-icon/floating-icon.component";
import LoaderComponent from "../../components/loader/loader";
import { isAdmin } from "../../utils/common";

const HomeTemplate = ({
  loading,
  categories,
  posts,
  search,
  searchResults = [],
  handleSearch,
  navigate,
}) => {
  return (
    <PageWrapperComponent>
      <div className="app-container bg">
        <AppHeaderComponent handleSearch={handleSearch} />

        {loading && (
          <div className="t-a-c" style={{ paddingTop: 125 }}>
            <LoaderComponent
              path={require("./../../assets/icons/loading-gif.gif")}
            />
          </div>
        )}

        {!loading && (
          <div style={{ paddingBottom: isAdmin() ? 100 : 0 }}>
            {search && (
              <div style={{ paddingTop: 105 }}>
                {" "}
                <AppProductsComponent
                  name="Search Results"
                  posts={searchResults}
                />{" "}
              </div>
            )}

            {!search && (
              <>
                <div>
                  <AppCategoriesComponent
                    search={search}
                    categories={categories}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <AppProductsComponent name="All Posts" posts={posts} />
                </div>
              </>
            )}
          </div>
        )}

        {isAdmin() && (
          <FloatingButtonComponent
            icon={<FaPlus />}
            onClick={() => navigate("/post/add")}
            position={{ bottom: "30px", right: "30px" }}
          />
        )}
      </div>
    </PageWrapperComponent>
  );
};

export default HomeTemplate;
